import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['element'];

  connect() {
    this.checkedClass = 'y-choice-tag--checked';

    this.elementTargets.forEach((element) => {
      const input = element.querySelector('input');
      this.toggleClass(element, input.checked);
    });

    this.boundHandleChange = this.handleChange.bind(this);
    this.element.addEventListener('change', this.boundHandleChange);
  }

  handleChange(event) {
    const input = event.target;
    const wrapper = input.closest('.y-choice-tag');

    if (wrapper) {
      this.toggleClass(wrapper, input.checked);
    }
  }

  toggleClass(element, isChecked) {
    if (isChecked) {
      element.classList.add(this.checkedClass);
    } else {
      element.classList.remove(this.checkedClass);
    }
  }

  disconnect() {
    this.element.removeEventListener('change', this.boundHandleChange);
  }
}
